<ng-container
  *ngTemplateOutlet="
    customInputTemplate ? customInputTemplate.template : baseInput;
    context: {
      ref: this,
      control: control,
      name: formControlName,
      disabled: control.disabled,
      inputPlaceholder: inputPlaceholder
    }
  "
>
</ng-container>
<ng-template #baseInput>
  <input
    #input
    type="text"
    [attr.id]="inputId"
    [formControl]="control"
    [attr.placeholder]="inputPlaceholder | translate"
    [attr.class]="inputClass"
    (keyup.enter)="input.blur()"
  />
</ng-template>
