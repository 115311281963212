import { AfterContentInit, Component, ContentChild, ElementRef, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppService } from '@app/app.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { clamp } from 'lodash-es';
import { UserSettingsQuery } from 'timeghost-api';

import { DurationInputControlDirective } from './duration-input-control.directive';
import { parseDuration } from './duration-input-utils';
@UntilDestroy()
@Component({
  selector: 'tg-duration-input-control',
  templateUrl: './duration-input-control.component.html',
  styleUrls: ['./duration-input-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DurationInputControlComponent),
      multi: true,
    },
  ],
  host: {
    '(click)': 'onPreviewClick($event)',
    '(focus)': 'onPreviewClick($event)',
  },
})
export class DurationInputControlComponent implements ControlValueAccessor, OnInit, AfterContentInit {
  private _control: FormControl = new FormControl(null, { updateOn: 'blur' });
  propagateChange: Function = () => {};
  propagateTouched: Function = () => {};
  registerOnChange(fn: (_: any) => void): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  get control(): FormControl {
    return this._control;
  }
  @Input() inputPlaceholder = 'time.input';
  @Input() inputId: string;
  @Input() inputClass: string;
  @Input() formControlName: string;

  @ContentChild(DurationInputControlDirective)
  customInputTemplate: DurationInputControlDirective;
  constructor(
    private userSettingsQuery: UserSettingsQuery,
    private appService: AppService,
    private elementRef: ElementRef<HTMLElement>
  ) {}
  ngOnInit() {
    this._control.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.handleInputChange(value);
    });
  }
  setDisabledState(isDisabled: boolean) {
    if (isDisabled !== this.control.disabled) this.control[isDisabled ? 'disable' : 'enable']();
  }
  ngAfterContentInit() {}
  writeValue(_time: string) {
    const t = this.parseTime(_time);
    if (!t) return;
    this.control.setValue(t, {
      emitEvent: false,
    });
  }
  handleInputChange(value: string) {
    const t = this.parseTime(value);
    if (!t) return;
    const modelValue = t;
    this.control.setValue(modelValue, {
      emitEvent: false,
    });
    this.propagateChange(modelValue);
    this.propagateTouched(modelValue);
  }

  parseTime(timeStr: any): string {
    if (!timeStr) return '00:00';
    const [pHours, pMinutes] = parseDuration(timeStr);
    let hours = 0,
      minutes = 0;
    if (pHours === undefined) hours = pMinutes;
    else {
      hours = pHours;

      if (pMinutes > 59) {
        const gb = pMinutes % 60;
        hours += Math.floor((pMinutes - gb) / 60);
        minutes = gb;
      } else minutes = pMinutes;
    }
    return [
      `${(hours ?? 0).toFixed(0)}`.padStart(2, '0'),
      `${clamp(minutes ?? 0, 0, 59).toFixed(0)}`.padStart(2, '0'),
    ].join(':');
  }
  private selectInput(ev: Event) {
    const el: HTMLElement = this.elementRef?.nativeElement;
    if (!el) {
      return;
    }
    const input = el.querySelector('input');
    if (input) {
      ev.preventDefault();
      input.select();
    }
  }
  onPreviewClick(ev: Event) {
    this.selectInput(ev);
  }
}
